<template lang="pug">
empty-datadog
</template>

<script>
import EmptyDatadog from './empty.react';

export default {
  components: {
    EmptyDatadog,
  },
  name: 'empty',
};
</script>
