import React from 'react';
import FairwindsLogo from '~reactComponents/Icons/FairwindsLogo.react';

import './empty.react.scss';

const EmptyDatadog = () => {
  return (
    <div className="empty-datadog">
      <FairwindsLogo />
      <h1 className="datadog-error-message">
        Please select an organization and cluster <br /> to get started
      </h1>
    </div>
  );
};

export default EmptyDatadog;
